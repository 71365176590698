<template>
  <div id="app">
    <el-container>
      <el-header style="position: absolute; top: 0; width: 100%; height: 80px; border-bottom: 1px solid #e6e6e6 ;">
        <div style="display: flex; align-items: center; height: 100%;">
          <img src="./assets/logo.jpg" style="height: 40px; margin-right: 30px;">
          <h3 style="margin-right: 30px;" v-if="!isMobile">水卡管理系统</h3>
          <el-menu class="el-menu-demo" mode="horizontal" default-active="1">
            <el-menu-item index="1">首页</el-menu-item> 
          </el-menu>
          <el-button type="primary" style="margin-left: auto;" @click="exitLogin">退出登录</el-button>
        </div>
      </el-header>

      <el-container style="position: absolute; top: 80px; bottom: 50px; width: 100%;">
        <el-aside width="200px">
          <el-menu default-active="1" class="el-menu-vertical-demo" style="height: 100%;" :router="true" :collapse="collapse">
            <el-menu-item index="/" >
              <i class="el-icon-s-home"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="/shuikamanage">
              <i class="el-icon-menu"></i>
              <span slot="title">水卡管理</span>
            </el-menu-item>
            <el-menu-item index="/ordermanage">
              <i class="el-icon-document"></i>
              <span slot="title">订单管理</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main class="main-box">
          <router-view />
        </el-main>
      </el-container>
      <el-footer
        style="position: absolute; bottom: 0; width: 100%; height: 50px; background-color: #2c3e50;"></el-footer>
    </el-container>

  </div>
</template>

<script>

export default {
  data(){
    return{
      isMobile: false,
      collapse: false
    }
  },
  created(){
    if(localStorage.getItem('pwd') == null){
      let pwd = prompt('请输入密码')
      localStorage.setItem('pwd', pwd)
      window.location.reload()
    }
    this.isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.collapse = this.isMobile
  },
  methods:{
    exitLogin(){
      localStorage.removeItem('pwd')
      window.location.reload()
    }
  }
}
</script>


<style>

@media (max-width: 768px) {
  .main-box{
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 60px;
    right: 0;
    overflow: auto
  }
}

</style>
