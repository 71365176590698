<template>
  <div>
    <h2>欢迎使用水卡管理系统！</h2>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {

  }
}
</script>
